<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <b-badge
            @click="modal_filter = true"
            style="height: 8vh"
            class="w-100 badge-glow py-1 cursor-pointer"
            variant="primary"
          >
            <div class="mt-50 text-large">{{ lang("t_month") }}</div>
            <div class="mt-50 text-large">
              {{ collection_statistics["selected_month"] }}
            </div>
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            style="height: 8vh"
            class="w-100 badge-glow py-1"
            variant="primary"
          >
            <div class="mt-50 text-large">{{ lang("t_target") }}</div>
            <div class="mt-50 text-large">
              {{
                parseFloat(
                  collection_statistics.collection_goal
                ).toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                })
              }}
            </div>
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            style="height: 8vh"
            class="w-100 badge-glow py-1"
            variant="primary"
          >
            <div class="mt-50 text-large">{{ lang("t_completed") }}</div>
            <div class="mt-50 text-large">
              {{
                parseFloat(
                  collection_statistics.total_collection_payment
                ).toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                })
              }}
            </div>
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            style="height: 8vh"
            class="w-100 badge-glow py-1"
            variant="primary"
          >
            <div class="mt-50 text-large">
              {{ lang("t_remaining") }}
            </div>
            <div class="mt-50 text-large">
              {{
                parseFloat(
                  collection_statistics.remaining_collection
                ).toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                })
              }}
            </div>
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            style="height: 8vh"
            class="w-100 badge-glow py-1"
            variant="primary"
          >
            <div class="mt-50 text-large">{{ lang("t_dailyTarget") }}</div>
            <div class="mt-50 text-large">
              {{
                parseFloat(
                  collection_statistics.daily_collection_goal
                ).toLocaleString("tr-TR", {
                  style: "currency",
                  currency: "TRY",
                })
              }}
            </div>
          </b-badge>
        </b-col>
        <b-col>
          <b-badge
            style="height: 8vh"
            class="w-100 badge-glow py-1"
            variant="primary"
          >
            <div class="mt-50 text-large">
              {{ lang("t_compilationRate") }}
            </div>
            <div class="mt-50 text-large">
              {{ `%${collection_statistics.collection_rate}` }}
            </div>
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col>
          <vue-apex-charts
            width="99%"
            type="line"
            height="500"
            :options="collection_chart_options_daily.chartOptions"
            :series="collection_chart_options_daily.series"
          />
        </b-col>
        <b-col>
          <vue-apex-charts
            width="99%"
            type="line"
            height="500"
            :options="collection_chart_options_monthly.chartOptions"
            :series="collection_chart_options_monthly.series"
          />
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="lang('t_filters')"
      centered
      v-model="modal_filter"
      @ok="GetCollectionStatistics(dashboard_info.selected_month)"
    >
      <b-form-group :label="lang('t_targetedMonth')" label-for="targeted_month">
        <flat-pickr
          v-model="dashboard_info.selected_month"
          class="form-control"
          :config="{ dateFormat: 'Y-m' }"
        />
      </b-form-group>
      <b-form-group :label="lang('t_extension')" label-for="extens">
        <v-select
          id="extens"
          v-model="dashboard_info.selected_agents"
          :options="agents"
          :placeholder="lang('t_agents')"
          :reduce="(val) => val.identifier"
          label="username"
          multiple
        ></v-select>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import { BContainer, BModal, BFormGroup, BRow, BCol, BCard, BCardText, BButton, BBadge } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      collection_statistics: {},
      modal_filter: false,
      agents: [],
      dashboard_info: {
        selected_month: '',
        selected_agents: []
      },
      daily_chart_info: {
        "09": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "13": 0,
        "14": 0,
        "15": 0,
        "16": 0,
        "17": 0,
        "18": 0,
      },
      monthly_chart_info: {

      },
      collection_chart_options_daily: {
        series: [
          {
            name: globalThis._lang("t_completed"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: globalThis._lang("t_goal"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],

        chartOptions: {
          title: {
            text: globalThis._lang("t_dailyTarget"),
            align: 'center',
            margin: 50,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: $themeColors.primary,
            },
          },
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: true,
          },
          colors: ['#d0ccff', $themeColors.secondary],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: ['09', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      collection_chart_options_monthly: {
        series: [
          {
            name: globalThis._lang("t_completed"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
          {
            name: globalThis._lang("t_goal"),
            data: [0, 0, 0, 0, 0, 0, 0, 0],
          },
        ],

        chartOptions: {
          title: {
            text: globalThis._lang("t_monthlyTarget"),
            align: 'center',
            margin: 50,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: undefined,
              color: $themeColors.primary,
            },
          },
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: true,
          },
          colors: ['#d0ccff', $themeColors.secondary],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary, '#ebe9f1'],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [],
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '1rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          grid: {
            borderColor: '#e7eef7',
            padding: {
              top: -20,
              bottom: -10,
              left: 20,
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
    }
  },
  components: {
    BContainer,
    flatPickr,
    BModal,
    vSelect,
    VueApexCharts,
    BBadge,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BButton
  },
  methods: {
    GetCollectionStatistics: async function (_date) {

      var response = (
        await this.$http_in.get(`spv/v1/CollectionStatisticsDashboard/${_date}/${JSON.stringify(this.dashboard_info.selected_agents)}`)
      ).data;

      console.log("res", response);
      this.collection_statistics = {
        ...response.bar_info,
      };
      this.collection_statistics['selected_month'] = new Date(_date).toLocaleString('default', { month: 'long' });

      this.daily_chart_info = {
        "09": 0,
        "10": 0,
        "11": 0,
        "12": 0,
        "13": 0,
        "14": 0,
        "15": 0,
        "16": 0,
        "17": 0,
        "18": 0,
      };

      this.monthly_chart_info = {};
      for (const item of Object.keys(this.daily_chart_info)) {
        for (const item2 of response.daily_chart_info) {
          if (parseInt(item2.collection_time) <= parseInt(item)) {
            this.daily_chart_info[item] +=
              parseInt(item2.collection_payment) || 0;
          }
        }
      }

      var hourly_goals = [];
      for (const index in Object.keys(this.daily_chart_info)) {
        hourly_goals.push(
          ((parseInt(index) + 1) *
            parseInt(this.collection_statistics.daily_collection_goal)) /
          10
        );
      }
      var _tmp = Object.values(this.daily_chart_info);
      _tmp.sort(function (a, b) {
        return a - b;
      });
      console.log("tmp", _tmp);
      console.log("hourly_goals", hourly_goals);
      // this.collection_chart_options_daily.series[0].data = _tmp;
      // this.collection_chart_options_daily.series[1].data = hourly_goals;

      this.collection_chart_options_daily.chartOptions = { ...this.collection_chart_options_daily.chartOptions, categories: Object.keys(this.daily_chart_info) };
      this.collection_chart_options_daily.series[0].data = _tmp;
      this.collection_chart_options_daily.series[1].data = hourly_goals;
      this.collection_chart_options_daily.series[1].data = hourly_goals;


      let date = new Date(_date);
      this.collection_statistics['selected_month'] = new Date(_date).toLocaleString('default', { month: 'long' });
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var _bussiness_days = this.businessDays(firstDay, lastDay);
      var bussiness_days = _bussiness_days.length;
      var daily_goals = [];
      var daily_goals_columns = [];
      console.log("_bussiness_days", _bussiness_days);
      for (let index = 0; index < bussiness_days; index++) {
        // daily_goals_columns.push(parseInt(index) + 1);
        daily_goals.push(
          parseInt(
            ((parseInt(index) + 1) *
              parseInt(this.collection_statistics.collection_goal)) /
            bussiness_days
          )
        );
      }

      var _array = [];
      for (const item of _bussiness_days) {
        this.monthly_chart_info[item] = 0;
        console.log("item", item);
        for (const item3 of response.monthly_chart_info) {
          console.log("item3", item3);

          if (new Date(item3.collection_date).getDate() <= parseInt(item)) {
            console.log("item33", parseInt(item3.collection_payment));

            this.monthly_chart_info[item] += parseInt(item3.collection_payment);
          }

        }
        this.modal_filter = false;
      }
      console.log("this.monthly_chart_info", this.monthly_chart_info);
      var _tmp = Object.values(this.monthly_chart_info);
      _tmp.sort(function (a, b) {
        return a - b;
      });
      console.log("daily_goals", daily_goals);

      this.collection_chart_options_monthly.chartOptions = { ...this.collection_chart_options_monthly.chartOptions, categories: _bussiness_days };
      this.collection_chart_options_monthly.series[0].data = _tmp;
      this.collection_chart_options_monthly.series[1].data = daily_goals;
      this.collection_statistics['selected_month'] = new Date(_date).toLocaleString('default', { month: 'long' });



    },
    businessDays(date1, date2) {
      var days = ['N', 'Y', 'Y', 'Y', 'Y', 'Y', 'N'];

      var d1 = new Date(date1);
      var d2 = new Date(date2);

      var isGunu = 0;
      var _days = [];
      while (true) {

        if (d1 > d2) {
          break;
        }

        var dayName = days[d1.getDay()];

        if (dayName != "N") {
          isGunu++;
          _days.push(d1.getDate());
        }

        d1.setDate(d1.getDate() + 1);
      }
      return _days;
    },
    get_agents: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/User`)
      ).data;

      this.agents = response;
    },

  },
  mounted: function () {

    this.get_agents();
    let date = new Date();
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
    this.dashboard_info.selected_month = `${date.getFullYear()}-${month}`;
    this.GetCollectionStatistics(this.dashboard_info.selected_month);

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
