<template>
  <div>
    <b-row class="mb-1 mx-0" style="overflow-x: auto; white-space: nowrap">
      <b-button
        style="width: 130px"
        class="ml-1"
        :variant="
          selectedButton != 'targeting' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'targeting'"
      >
        {{ lang("t_targeting") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'collections' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'collections'"
      >
        {{ lang("t_completed") }}
      </b-button>
      <b-button
        style="width: 130px"
        class="ml-50"
        :variant="
          selectedButton != 'dashboard' ? 'outline-primary' : 'relief-primary'
        "
        pill
        :options="optionsButton"
        @click="selectedButton = 'dashboard'"
      >
        {{ lang("t_dashboard") }}
      </b-button>
    </b-row>
    <targeting v-if="selectedButton == 'targeting'"></targeting>
    <collections v-if="selectedButton == 'collections'"></collections>
    <dashboard v-if="selectedButton == 'dashboard'"></dashboard>
  </div>
</template>

<script>
import { BFormRadioGroup, BFormGroup, BRow, BCol, BCard, BCardText, BLink, BButton } from 'bootstrap-vue'

import Collections from './Collections/list/List.vue'
import Targeting from './Targeting/list/List.vue'
import Dashboard from './Dashboard.vue'

export default {
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      selectedButton: 'targeting',
      optionsButton: [
        { text: globalThis._lang("t_collectionTargeting"), value: 'targeting' },
        { text: globalThis._lang("t_completed"), value: 'collections' },
        { text: globalThis._lang("t_dashboard"), value: 'dashboard' },
      ],
    }
  },
  components: {
    Collections,
    Targeting,
    Dashboard,
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton
  },
}
</script>

<style>
</style>
