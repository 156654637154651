<template>
  <div>
    <!-- Filters -->
    <!--
    <list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :extens-options="extensOptions"
      :project-options="projectOptions"
      :profile-options="profileOptions"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :classification-options="classificationOptions"
      :grouo-options="groupOptions"
      @refetch-data="refetchData"
      :isEdit="isEdit"
      :currentData="currentData"
    />

   <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button
                class="mr-1"
                variant="success"
                @click="
                  modal_excel = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_import") }}</span>
              </b-button>
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(queue)="data">
          {{
            queues.find((e) => e.internal_name == data.item.queue) != undefined
              ? queues.find((e) => e.internal_name == data.item.queue)
                  .display_name
              : data.item.queue
          }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ dataMeta.of }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Month -->
          <validation-provider #default="validationContext" name="Month">
            <b-form-group
              :label="lang('t_targetedMonth')"
              label-for="targeted_month"
            >
              <flat-pickr
                v-model="currentData.collection_month"
                class="form-control"
                :config="{ dateFormat: 'Y-m' }"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Targeting -->
          <validation-provider #default="validationContext" name="Targeting">
            <b-form-group
              :label="lang('t_target')"
              label-for="collection_targeting"
            >
              <b-form-input
                id="collection_targeting"
                v-model="currentData.collection_goal"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Agents -->
          <validation-provider
            #default="validationContext"
            name="titles"
            rules="required"
          >
            <b-form-group
              :label="lang('t_agent')"
              label-for="titles"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.agent"
                :options="agents"
                :placeholder="lang('t_selectTheAgent')"
                :reduce="(val) => val.identifier"
                value="id"
                label="username"
              ></v-select>
              <!-- <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="titles"
            rules="required"
          >
            <b-form-group
              :label="lang('t_queue')"
              label-for="titles"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.queue"
                :options="queues"
                :placeholder="lang('t_queue')"
                :reduce="(val) => val"
                value="internal_name"
                label="display_name"
              ></v-select>
              <!-- <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
            </b-form-group>
          </validation-provider>

          <!-- Form Actions
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{lang('t_add')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{lang('t_cancel')}}
            </b-button>
          </div>
          -->
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      @ok="read_data"
      v-model="modal_excel"
      :title="lang('t_importRecords')"
      centered
    >
      <b-row align-h="end">
        <b-button
          href="template_examples/voyce_goal_import.xlsx"
          :exact="true"
          size="sm"
          variant="outline-success"
          >{{ lang("t_exampleFile") }}</b-button
        >
      </b-row>
      <b-row class="mt-1">
        <b-form-file
          :browse-text="lang('t_file')"
          v-model="files"
          :placeholder="lang('t_uploadFileOnClick')"
          :drop-placeholder="lang('t_uploadFile')"
          no-drop
          required
          accept=".xlsx, .xls"
        />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BSidebar, BFormTextarea, BCardText, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useList from './useList'
import storeModule from '../storeModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email, password } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import flatPickr from 'vue-flatpickr-component'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormFile,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSidebar,
    BFormTextarea,
    BCardText,
    flatPickr,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem('language') || "tr"),
      isEdit: false,
      currentData: {
        _id: "",
        collection_month: new Date().toISOString().substr(0, 7),
        collection_goal: "",
        agent: "",
        queue: {}
      },
      files: {},
      modal_record_details: false,
      modal_excel: false,
      required,
      alphaNum,
      email,
      password,
      records: [],
      agents: [],
      queues: [],
      tableColumns: [
        { key: 'collection_month', label: globalThis._lang("t_targetedMonth"), sortable: true },
        { key: 'collection_goal', label: globalThis._lang("t_target"), sortable: true },
        { key: 'agent', label: globalThis._lang("t_agent"), sortable: true },
        { key: 'queue', label: globalThis._lang("t_queue"), sortable: true },
        { key: 'actions', label: globalThis._lang('t_actions'), sortable: false },
      ],
    }
  },
  mounted: async function () {
    await this.get_queues();
    this.get_records();
    this.get_agents();
  },
  methods: {
    async read_data() {
      this.progress = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: 'binary' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);

        for (const item of _data) {
          console.log('item', item);
          this.currentData = {
            id: "",
            collection_month: item['Hedef Ay'],
            collection_goal: item['Hedef Tutar'],
            agent: item['Agent'] + '@' + globalThis.username.split('@')[1],
            queue: this.queues.find(e => e.display_name == item['Kuyruk'])

          };

          this.edit = false;
          this.add_record();
        }
        this.get_records();
        this.progress = false;
        this.modal_excel = false;


      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    get_records: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/CollectionGoal`)
      ).data;
      console.log("CollectionGoal", response);
      this.records = response;
    },
    get_agents: async function () {
      var response = (
        await this.$http_in.get(`spv/v1/User`)
      ).data;
      console.log("Agents", response);
      this.agents = response;
    },
    get_queues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter(e => e.internal_name != item.internal_name)
        }
      }
    },
    check_data() {
      // if (this.currentData.number == '') {
      // this.alert_message = globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields");
      // this.alert_type = 'warning';
      // this.is_alert = true;
      // return true false;
      // }
    },
    add_record: async function () {
      // if (this.black_list_number.number == '') {
      //   this.alert_message = globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields");
      //   this.alert_type = 'warning';
      //   this.is_alert = true;
      //   return;
      // }
      // if (this.check_data) {
      //   // alert
      //   return;
      // }

      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(
            `spv/v1/CollectionGoal`,
            { data: JSON.stringify(this.currentData) }
          )
        ).data;
      } else {
        response = (
          await this.$http_in.put(
            `spv/v1/CollectionGoal`,
            {
              data: JSON.stringify(this.currentData),

            }
          )
        ).data;
      }

      // if (response != null || response != undefined) {
      //   if (response.command == 'UPDATE') {
      //     this.alert_message = `${this.black_list_number.number} ${globalThis._lang("t_hasBeenUpdatedSuccessfully")}`;
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   } else {
      //     this.alert_message = globalThis._lang("t_hasBeenCreatedSuccessfully");
      //     this.alert_type = 'success';
      //     this.is_alert = true;
      //   }
      // }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      var response = (
        await this.$http_in.delete(`spv/v1/CollectionGoal/${this.currentData._id}`)
      ).data;


      if (response != null || response != undefined) {
        this.$swal({
          icon: 'success',
          title: this.lang('t_processSuccess'),
          text: this.lang('t_customersHaveBeenSuccessfullyDeleted'),
          confirmButtonText: this.lang('t_ok'),
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        _id: "",
        collection_month: new Date().toISOString().substr(0, 7),
        collection_goal: "",
        agent: "",
        queue: {}
      }
    },
    updateSelected(item) {
      console.log("item", item);
      this.currentData = { ...item, queue: this.queues.find(e => e.internal_name == item.queue) };
      console.log("item", item);
      this.isEdit = true;
      // this.isAddNewUserSidebarActive = true;
      this.modal_record_details = true;
    },
    confirmText(item) {
      this.currentData = item;
      console.log("item", item);
      this.$swal({
        title: this.lang('t_deleteRecord'),
        text: this.lang('t_areYouSureYouWantToDeleteTheSelectedRecord'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.lang('t_yes'),
        cancelButtonText: this.lang('t_cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',

        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delete_record();
        }
      })
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      contact: '',
    }

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    const isAddNewUserSidebarActive = ref(false)




    const groupOptions = [
      { label: 'Grup1', value: '123' },
      { label: 'Grup4', value: '456' },
      { label: 'Grup3', value: '9999' },
      { label: 'Grup2', value: '654' },
    ]
    const classificationOptions = [
      { label: '5XX', value: '123' },
      { label: '905XX', value: '456' },
      { label: '5105XX', value: '9999' },
      { label: '5405XX', value: '654' },
      { label: 'isnet', value: '654' },
    ]
    const extensOptions = [
      { label: '123', value: '123' },
      { label: '456', value: '456' },
      { label: '9999', value: '9999' },
      { label: '654', value: '654' },
    ]
    const projectOptions = [
      { label: 'Abc', value: '1' },
      { label: 'Proje2', value: '2' },
      { label: 'Proje3', value: '3' },
      { label: 'Proje4', value: '4' },
    ]
    const profileOptions = [
      { label: 'Admin', value: '1' },
      { label: 'Profil2', value: '2' },
      { label: 'Profil3', value: '3' },
      { label: 'Profil4', value: '4' },
    ]


    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      groupOptions,
      classificationOptions,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
